<template>
	<div class="modal-bg">
		<ion-header>
			<ion-toolbar color="ari">
				<ion-title>Preview Image</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="closePupop()"
						><ion-icon :icon="closeCircleOutline"> </ion-icon
					></ion-button>
				</ion-buttons>
				<ion-progress-bar
					v-if="store.state.isLoading"
					type="indeterminate"
				></ion-progress-bar>
			</ion-toolbar>
		</ion-header>
		<ion-content :fullscreen="true" color="light">
			<div class="modal-content">
				<form @submit.prevent="sendPhoto">
					<div>
						<img
							class="imgPreview"
							:src="store.state.uploadImg.imgPreview"
							alt="Image"
						/>
						<!-- <ion-item>
							<input
								class="inputMsg"
								v-model="store.state.uploadImg.Message"
								placeholder="type a messages.."
							/>
						</ion-item> -->
					</div>
					<div class="btn-submit">
						<ion-button
							type="submit"
							expand="block"
							color="ari"
							:disabled="store.state.isLoading"
							>Send</ion-button
						>
					</div>
				</form>
			</div>
		</ion-content>
	</div>
</template>

<script>
import {
	IonContent,
	IonHeader,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonButton,
	IonIcon,
	IonProgressBar,
} from "@ionic/vue";
import { closeCircleOutline } from "ionicons/icons";
import { defineComponent, reactive, inject } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
	name: "DoctorEndChat",
	components: {
		IonContent,
		IonHeader,
		IonTitle,
		IonToolbar,
		IonButtons,
		IonButton,
		IonIcon,
		IonProgressBar,
	},
	setup() {
		const store = inject("store");
		const state = reactive({ status: null });
		const route = useRoute();
		const ticketID = route.params.id;
		function closePupop() {
			store.state.uploadImg.isOpenPopup = false;
		}

		async function sendPhoto() {
			store.state.isLoading = true;
			if (store.state.uploadImg.ChatPhotoExtension != null) {
				try {
					const r = await store.chat.addPhoto(ticketID);
					if (r.status == 200) {
						//remove value after success upload
						store.state.uploadImg.Message = null;
						store.state.uploadImg.ChatPhotoName = null;
						store.state.uploadImg.ChatPhotoExtension = null;
						store.state.uploadImg.ChatPhoto = null;
						store.state.uploadImg.imgPreview = null;
						store.state.isLoading = false;
					}
				} catch (err) {
					store.state.isLoading = false;
					console.log("Err upload photo", err);
				}
			}
			store.state.uploadImg.isOpenPopup = false;
			store.state.isLoading = false;
		}
		return {
			store,
			closeCircleOutline,
			closePupop,
			sendPhoto,
			state,
		};
	},
	// emits: ["toPopUploadClose"],
	// methods: {
	// 	sendEvent() {
	// 		this.$emit("toPopUploadClose", false);
	// 	},
	// },
});
</script>

<style scoped>
ion-icon {
	font-size: 30px;
}

.modal-content {
	margin: 0 auto;
	align-content: center;
	text-align: center;
}
form {
	/* margin-top: 100px !important; */
	background-color: var(--ion-color-light);
	margin-bottom: 50px;
}
/* ion-textarea {
	margin: 0 auto;
	padding: 5px;
	min-height: 147px !important;
	background-color: #fff;
} */
ion-content {
	--overflow: hidden;
}
.btn-submit {
	margin-top: 10px;
}
.gap {
	margin-top: 100px !important;
	background-color: var(--ion-color-light);
}
.status {
	margin-bottom: 25px;
}
.imgPreview {
	margin: 0 auto;
	max-height: 300px;
	margin-bottom: 10px;
	margin-top: 10px;
}

.inputMsg {
	width: 100%;
	height: 50px !important;
	border: none;
	outline: none;
	border-radius: 10px;
}
</style>
