<template>
	<ion-page>
		<div class="modal-bg">
			<ion-header>
				<ion-toolbar color="ari">
					<ion-buttons slot="start">
						<ion-back-button
							default-href="/ticket-onprocess"
							text=""
						></ion-back-button>
					</ion-buttons>
					<ion-title>Catatan Dokter</ion-title>
					<!-- <ion-buttons slot="end">
						<ion-button @click="closePopup"
							><ion-icon :icon="closeCircleOutline"> </ion-icon
						></ion-button>
					</ion-buttons> -->
					<ion-progress-bar
						v-if="store.state.isLoading"
						type="indeterminate"
					></ion-progress-bar>
				</ion-toolbar>
			</ion-header>
			<ion-content :fullscreen="true" color="light" slot="fixed">
				<div class="modal-content">
					<ion-row>
						<ion-col size="12">
							<ion-label class="error" v-if="state.isErrorFollowUpId"
								>Status tiket wajib dipilih</ion-label
							>
						</ion-col>
					</ion-row>
					<ion-row>
						<ion-col size="5"><ion-label>Status Tiket</ion-label></ion-col>
						<!-- <pre>{{ list }}</pre> -->
						<ion-col size="7">
							<select
								v-model="state.followUpId"
								placeholder="pilih"
								required="true"
								class="pilih"
							>
								<option :value="null">pilih status</option>
								<!-- <option value="1">Kirim Obat</option>
								<option value="2">Kirim Resep Tanpa Obat</option>
								<option value="3">Konsultasi Saja</option> -->
								<option
									v-for="(d, i) in list"
									:key="i"
									:value="d.TICKET_FOLLOWUP_ID"
									>{{ d.TICKET_FOLLOWUP_DESC }}</option
								>
							</select>
						</ion-col>
					</ion-row>
					<ion-row>
						<ion-col size="12">
							<ion-label class="error" v-if="state.isErrorDoctorNote"
								>Catatan untuk pasien wajib diisi</ion-label
							>
						</ion-col>
					</ion-row>
					<ion-row>
						<ion-col size="12"><ion-label>Catatan Pasien</ion-label></ion-col>
					</ion-row>
					<ion-row>
						<ion-col size="12">
							<textarea
								class="noteEndChat"
								v-model="state.doctorNote"
								cols="100"
								:auto-grow="true"
								:rows="6"
								v-on:click="state.isErrorDoctorNote = null"
								placeholder="Input catatan untuk pasien"
							></textarea>
						</ion-col>
					</ion-row>
					<ion-row v-if="state.followUpId == '1' || state.followUpId == '2'">
						<ion-col size="12"><ion-label>Catatan Admin</ion-label></ion-col>
					</ion-row>
					<ion-row v-if="state.followUpId == '1' || state.followUpId == '2'">
						<ion-col size="12">
							<ion-label class="error" v-if="state.isErrorDoctorNoteForNurse"
								>Catatan untuk admin wajib diisi</ion-label
							>
						</ion-col>
					</ion-row>
					<ion-row v-if="state.followUpId == '1' || state.followUpId == '2'">
						<ion-col size="12">
							<textarea
								class="noteEndChat"
								v-model="state.doctorNoteForNurse"
								cols="100"
								:auto-grow="true"
								:rows="6"
								v-on:click="state.isErrorDoctorNoteForNurse = null"
								placeholder="Input catatan untuk admin"
							></textarea>
						</ion-col>
					</ion-row>
					<div>
						<ion-button
							class="btn-submit"
							expand="full"
							color="ari"
							@click="DoctorEndTicket()"
							:disabled="store.state.isLoading"
							>Kirim</ion-button
						>
					</div>
				</div>
			</ion-content>
			<ion-toast
				position="top"
				color="danger"
				:is-open="toastFailed.value"
				:message="toastFailed.message"
				:duration="3000"
				@didDismiss="toastFailed.value = false"
			>
			</ion-toast>
		</div>
	</ion-page>
</template>

<script>
import {
	IonRow,
	IonCol,
	IonContent,
	IonHeader,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonButton,
	// IonIcon,
	// IonTextarea,
	// IonItem,
	IonLabel,
	// IonSelect,
	// IonSelectOption,
	IonProgressBar,
	onIonViewWillLeave,
	IonBackButton,
	IonPage,
	IonToast,
} from "@ionic/vue";
import { closeCircleOutline } from "ionicons/icons";
import {
	defineComponent,
	reactive,
	ref,
	inject,
	watchEffect,
	computed,
	onMounted,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { replaceWord } from "@/store/methods/utils/utils";

export default defineComponent({
	name: "DoctorEndChat",
	components: {
		IonRow,
		IonCol,
		IonContent,
		IonHeader,
		IonTitle,
		IonToolbar,
		IonButtons,
		IonButton,
		// IonIcon,
		// IonTextarea,
		// IonItem,
		IonLabel,
		// IonSelect,
		// IonSelectOption,
		IonProgressBar,
		IonBackButton,
		IonPage,
		IonToast,
	},
	setup() {
		//console.log("modal load");
		const state = reactive({
			// status: null,
			followUpId: null,
			doctorNote: null,
			doctorNoteForNurse: null,
			isErrorFollowUpId: false,
			isErrorDoctorNote: false,
			isErrorDoctorNoteForNurse: false,
		});
		const list = ref(null);
		const store = inject("store");
		const route = useRoute();
		const router = useRouter();
		const ticketID = computed(() => route.params.id);
		const toastFailed = reactive({
			value: false,
			message: "error",
		});

		onMounted(async () => {
			// console.log("onMounted");
			try {
				let r = await store.ticket.doctorLoadFollowupIdData();
				// console.log("r", r);
				if (r != null) {
					list.value = r.followupIdDataReponseModel;
				}
			} catch (error) {
				console.log(error);
			}
			// if (store.state.ticket.isOpenPopup) {
			// 	try {
			// 		let r = await store.ticket.doctorLoadFollowupIdData();
			// 		console.log("r", r);
			// 		if (r != null) {
			// 			list.value = r.followupIdDataReponseModel;
			// 		}
			// 	} catch (error) {
			// 		console.log(error);
			// 	}
			// }
		});
		watchEffect(async () => {
			if (state.followUpId != null) {
				state.isErrorFollowUpId = false;
			}
			if (state.doctorNote != null) {
				state.isErrorDoctorNote = false;
			}
			if (state.doctorNoteForNurse != null) {
				state.isErrorDoctorNoteForNurse = false;
			}
		});
		async function DoctorEndTicket() {
			state.isErrorFollowUpId = false;
			state.isErrorDoctorNote = false;
			state.isErrorDoctorNoteForNurse = false;
			if (state.followUpId == null) {
				state.isErrorFollowUpId = true;
				return;
			}
			if (state.doctorNote == null) {
				state.isErrorDoctorNote = true;
				return;
			}
			if (state.followUpId == "1" || state.followUpId == "2") {
				if (
					state.doctorNoteForNurse == null ||
					state.doctorNoteForNurse == ""
				) {
					state.isErrorDoctorNoteForNurse = true;
					return;
				}
			}
			store.state.isLoading = true;
			if (state.followUpId != null) {
				state.isErrorFollowUpId = false;
				try {
					let note = replaceWord(state.doctorNote.trim());
					if (note == "" || note == null || note == undefined) {
						store.state.isLoading = false;
						state.isErrorDoctorNote = true;
						return;
					}
					let noteForAdmin = null;
					if (state.followUpId == "1" || state.followUpId == "2") {
						noteForAdmin = replaceWord(state.doctorNoteForNurse.trim());
						if (
							noteForAdmin == "" ||
							noteForAdmin == null ||
							note == undefined
						) {
							store.state.isLoading = false;
							state.isErrorDoctorNoteForNurse = true;
							return;
						}
					}
					const r = await store.ticket.doctorEndConsultation(
						ticketID.value,
						state.followUpId,
						note,
						noteForAdmin
					);
					if (r.status === 200 && r.data.ResponseCode === "100") {
						store.state.isLoading = false;
						toastFailed.value = true;
						toastFailed.message = r.data.ResponseMessage;
						setTimeout(() => {
							router.replace("/conversation");
						}, 3100);
						return;
					}
					//console.log("response END-TICKET: ", r);
					if (r.status == 200) {
						//updating all list tiket
						//store.ticket.listStartConsultation();
						//store.ticket.listTicketComplete();
						store.state.isLoading = false;
						store.state.ticket.isOpenPopup = false;
						//redirect to ticket list start consultation
						router.replace("/ticket-complete");
					}
					store.state.ticket.isOpenPopup = false;
				} catch (err) {
					//console.log("error end chat", err);
					store.state.isLoading = false;
					store.state.ticket.isOpenPopup = false;
					store.ticket.listStartConsultation();
					router.replace("/ticket-onprocess");
				}
			}
			store.state.ticket.isOpenPopup = false;
		}
		function closePopup() {
			store.state.ticket.isOpenPopup = false;
		}
		onIonViewWillLeave(() => {
			//console.log("leave modal");
			store.state.ticket.isOpenPopup = false;
			localStorage.removeItem("doctorGreeting");
		});
		return {
			closeCircleOutline,
			DoctorEndTicket,
			closePopup,
			state,
			store,
			list,
			toastFailed,
		};
	},
	emits: ["toIsOpenFalse"],
	methods: {
		sendEvent() {
			this.$emit("toIsOpenFalse", false);
		},
	},
});
</script>

<style scoped>
ion-icon {
	font-size: 30px;
}
.noteEndChat {
	border: 1px solid #cacaca;
	padding-left: 5px;
	outline: none;
	max-height: 20px !important;
	width: 100% !important;
}

.modal-content {
	padding-top: 20px;
	margin: 0 5px;
}
.pilih {
	outline: none;
	height: 30px;
	margin-top: -5px;
	width: 100%;
	border: 1px solid #cacaca;
}

.btn-submit {
	/* z-index: 0; */

	bottom: 0;
	left: 0;
	right: 0;
	margin-bottom: 0;
	margin-left: 0;
	margin-right: 0;

	position: fixed;
	bottom: 0;
	width: 100%;
}

.status {
	margin-bottom: 25px;
}
.error {
	color: rgb(255, 0, 0);
	font-size: 12px;
}
ion-content {
	height: 50vh;
}
</style>
