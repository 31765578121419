<template>
	<ion-page>
		<ion-header>
			<ion-toolbar>
				<ion-back-button
					@click="backToPage('/conversation')"
					color="medium"
					default-href="/conversation"
					text=""
					slot="start"
				>
				</ion-back-button>

				<div class="ion-padding-horizontal top-header">
					<span>{{ patientName != null ? patientName : "Pasien" }}</span>
					<span>{{ nomorTiket }}</span>
				</div>
				<div slot="end">
					<ion-icon
						@click="backToPage('/conversation')"
						class="newIncomingChat"
						:icon="chatboxOutline"
					></ion-icon>
					<span class="totalBadge" v-if="store.state.totalNewTicket !== 0">
						{{ store.state.totalNewTicket }}
					</span>
				</div>

				<ion-chip @click="goToPageEndChat()" color="ari" slot="end">
					<ion-icon :icon="checkmarkCircleOutline"></ion-icon>
					<ion-label>
						end-chat ?
					</ion-label>
				</ion-chip>
				<ion-progress-bar
					v-if="store.state.isLoading"
					type="indeterminate"
				></ion-progress-bar>
			</ion-toolbar>
		</ion-header>

		<ion-content scroll-y="false">
			<div :style="chatBg" class="backgroundChat">
				<div v-if="sessionEndDate != null" class="sessionEndDate">
					Waktu konsultasi hingga {{ sessionEndDate }} WIB
				</div>
				<div class="bgChat" ref="scroll">
					<div>
						<div><p></p></div>
						<div v-for="(chat, index) in conversation" :key="index">
							<div class="bubbleWrapper">
								<div
									:class="[
										userOwn == chat.userId
											? 'inlineContainer own'
											: 'inlineContainer',
									]"
								>
									<div
										:class="[
											userOwn == chat.userId
												? 'ownBubble own'
												: 'otherBubble other',
										]"
									>
										{{ chat.message }}
										<div v-if="chat.uploadPhotoUrl != ''">
											<img
												class="image"
												:src="[chat.uploadPhotoUrl]"
												@click="viewImage(chat.uploadPhotoUrl)"
											/>
											<!-- last ion modal -->
										</div>
										<br />
									</div>
								</div>
								<span :class="[userOwn == chat.userId ? 'own' : 'other']">{{
									chat.time
								}}</span>
							</div>
						</div>
						<!-- modal popup image -->
						<ion-modal
							backdrop-dismiss="false"
							:is-open="isOpenRef"
							css-class="my-custom-class"
						>
							<div>
								<ion-header>
									<ion-toolbar>
										<ion-buttons slot="end">
											<ion-button @click="isOpenRef = !isOpenRef"
												>Close</ion-button
											>
										</ion-buttons>
									</ion-toolbar>
								</ion-header>
								<img class="imgPopup" :src="[imgPopUp]" />
							</div>
						</ion-modal>
					</div>
				</div>
			</div>
		</ion-content>
		<ion-toolbar>
			<ion-grid>
				<ion-row>
					<ion-col size="2">
						<!-- <div class="imgUpload">
								<icon-image class="iconImage"></icon-image>
							</div> -->
						<div class="image-upload">
							<label for="file-input" class="imgUpload" @click="takePhoto">
								<!-- <svg viewBox="0 0 24 24" class="icon-img">
										<path
											fill="currentColor"
											d="M4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H16L12,22L8,18H4A2,2 0 0,1 2,16V4A2,2 0 0,1 4,2M4,4V16H8.83L12,19.17L15.17,16H20V4H4M7.5,6A1.5,1.5 0 0,1 9,7.5A1.5,1.5 0 0,1 7.5,9A1.5,1.5 0 0,1 6,7.5A1.5,1.5 0 0,1 7.5,6M6,14L11,9L13,11L18,6V14H6Z"
										/>
									</svg> -->
								<img
									src="@/assets/relidoc/add-photo.svg"
									alt=""
									class="icon-img"
								/>
							</label>
							<!-- <icon-image class="iconImage"></icon-image> -->
							<!-- <ion-icon :icon="checkmarkCircleOutline"></ion-icon> -->

							<!-- <input
									id="file-input"
									type="file"
									@change="onPhotoSelect"
									accept="image/*"
								/> -->
						</div>
					</ion-col>
					<ion-col size="8">
						<input
							id="inputMsg"
							class="inputMsg"
							placeholder="type a messages.."
							type="text"
							v-model="msg"
						/>
					</ion-col>
					<ion-col size="2">
						<ion-button color="ari" @click="sendMessage(msg)" expand="block"
							>Send</ion-button
						>
					</ion-col>
				</ion-row>
			</ion-grid>
		</ion-toolbar>
		<ion-modal
			css-class="endChatClass"
			:is-open="store.state.ticket.isOpenPopup"
			:backdrop-dismiss="false"
		>
			<doctor-end-chat></doctor-end-chat>
		</ion-modal>
		<ion-modal
			:is-open="store.state.uploadImg.isOpenPopup"
			:backdrop-dismiss="false"
		>
			<upload-image-onchat> </upload-image-onchat>
		</ion-modal>
		<ion-alert
			:is-open="isChatExp"
			header="Expired Sesi Konsultasi"
			message="Waktu habis. Tiket akan diakhiri otomatis oleh sistem"
			css-class="my-custom-class"
			:buttons="buttonsAlert"
			@didDismiss="onOkButton"
			backdrop-dismiss="false"
		>
		</ion-alert>
		<!-- <ion-alert
			:is-open="isChatEnd"
			header="Sesi Konsultasi Berakhir"
			message="Tiket telah diakhiri."
			css-class="my-custom-class"
			:buttons="buttonsAlert"
			@didDismiss="onOkButton"
			backdrop-dismiss="false"
		>
		</ion-alert> -->
	</ion-page>
</template>

<script>
import { Camera, CameraResultType } from "@capacitor/camera";
import {
	chevronBackOutline,
	sendOutline,
	paperPlaneOutline,
	imageOutline,
	checkmarkCircleOutline,
	chatboxOutline,
} from "ionicons/icons";
import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";
import { inject, ref, computed } from "vue";
import DoctorEndChat from "@/components/DoctorEndChat.vue";
import UploadImageOnchat from "@/components/UploadImageOnChat.vue";
import db from "@/fb";
import { catchAsync } from "../../store/methods/utils/errorCatch";
import { compressImgToBase64 } from "../../store/methods/utils/utils";
import {
	IonPage,
	IonHeader,
	IonToolbar,
	IonBackButton,
	IonButtons,
	IonButton,
	// IonList,
	// IonItem,
	// IonAvatar,
	IonLabel,
	IonContent,
	IonModal,
	IonGrid,
	IonCol,
	IonRow,
	IonChip,
	IonIcon,
	IonProgressBar,
	IonAlert,
	onIonViewDidEnter,
	// onIonViewDidLeave,
} from "@ionic/vue";
import { replaceWord, formatDate } from "@/store/methods/utils/utils";
// import {Base64} from "@ionic-native/base64"
export default {
	components: {
		// IconImage,
		IonPage,
		IonHeader,
		IonToolbar,
		IonBackButton,
		IonButtons,
		IonButton,
		// IonList,
		// IonItem,
		// IonAvatar,
		IonLabel,
		IonContent,
		IonModal,
		IonGrid,
		IonCol,
		IonRow,
		IonChip,
		IonIcon,
		IonProgressBar,
		DoctorEndChat,
		UploadImageOnchat,
		IonAlert,

		// IonTextarea,
	},
	state: {
		availability: "tersedia",
	},
	setup() {
		const store = inject("store");
		const route = useRoute();
		const router = useRouter();
		var unsub = null;
		//get ticket ID from params
		const ticketID = route.params.id;
		//awal inialisasi
		const nomorTiket = computed(() => route.params.id);
		//console.log("chat", ticketID);
		//const ticketNo = route.params.id;
		//let doc = db.collection("tickets").doc(ticketID);
		const scroll = ref(null);
		const conversation = ref(null);
		// const ticketInfo = ref(null);
		const isChatExp = ref(null);
		const isChatEnd = ref(null);
		const sessionEndDate = ref(null);
		const buttonsAlert = ["Ok"];
		const chatBg = ref({
			backgroundImage: `url(${require("@/assets/relidoc/chat-bg.jpg")})`,
		});
		const patientName = computed(() => route.query.patientName);
		const doctorGreeting = computed(() => route.query.doctorGreeting);

		function viewImage(urlImage) {
			router.push(`/view-image?url=${urlImage}&parentRoute=TicketOnProcess`);
		}

		//const isOpenAlertRef = ref(null);
		//set variable msg
		const msg = ref("");
		//set variable modal
		const isOpenRef = ref(false);
		const imgPopUp = ref(null);
		var uploadPhotoUrl = "";
		const setOpen = (state, url) => {
			isOpenRef.value = state;
			imgPopUp.value = url;
		};
		// function popUploadOpen() {
		// 	store.state.ticket.isOpenPopup = true;
		// }
		function goToPageEndChat() {
			//console.log("goToPageEndChat");
			router.push(`/end-chat/${nomorTiket.value}`);
		}

		//function to sendMessage to firestore
		function sendMessage(pesan) {
			//const newId = route.params.id;
			if (msg.value.length) {
				let m = replaceWord(pesan.trim());
				store.chat.addMessage(m, nomorTiket.value, uploadPhotoUrl);
				msg.value = "";
				// scroll.value.scrollTo(0, 0)
				scroll.value.scrollTo(0, 100000);
			}
			msg.value = "";
		}

		//set userOwn for css chat
		const userOwn = computed(function() {
			return localStorage.getItem("DoctorId");
		});
		function onOkButton() {
			router.replace("/ticket-complete");
		}

		//seting realtime data chat
		function setRealtime() {
			//console.log("doctorGreeting", doctorGreeting.value);
			const newId = route.params.id;
			var doc = db.collection("tickets").doc(newId);
			unsub = doc.onSnapshot((docSnapshot) => {
				if (docSnapshot.data() != null) {
					//console.log("klik me");
					let snapshots = docSnapshot.data();
					if (snapshots.conversation == undefined) {
						router.replace("/ticket-onprocess");
					}
					sessionEndDate.value = formatDate(snapshots.sessionEndDate);

					isChatExp.value = snapshots.isChatExp;
					isChatEnd.value = snapshots.isChatEnd;
					//sorting and set conversation by key
					let ordered = Object.keys(snapshots.conversation)
						.sort()
						.reduce((obj, key) => {
							obj[key] = snapshots.conversation[key];
							return obj;
						}, {});
					//store to conversation state
					conversation.value = ordered;

					//generate welcome message
					const isGenerate = Object.entries(snapshots.conversation).find(
						function(v) {
							if (v[1].userId == localStorage.getItem("DoctorId")) {
								return true;
							}
						}
					);
					if (isGenerate == null) {
						store.chat.addMessage(doctorGreeting.value, newId, "");
					}
				}
			});
		}

		onIonViewDidEnter(async () => {
			// setTimeout(() => {
			// 	scroll.value.scrollTo(0, 100000);
			// }, 1000)
			//starting initial listener to get realtime update data from firestore
			//const newId = route.params.id;
			try {
				setRealtime();
			} catch (err) {
				console.log(err);
			}
			try {
				store.chat.updateStatusOnChatRoom(true, ticketID);
			} catch (error) {
				console.log("error updat status", error.toString());
			}
			store.state.ticket.isOpenPopup = false;

			//send message when keyboard enter
			document
				.querySelector("#inputMsg")
				.addEventListener("keyup", function(KeyboardEvent) {
					if (KeyboardEvent.keyCode === 13) {
						sendMessage(msg.value);
					}
				});
		});
		onBeforeRouteLeave(() => {
			const ticketNo = route.params.id;
			unsub();
			store.state.ticket.isOpenPopup = false;
			setTimeout(() => {
				try {
					store.chat.updateStatusOnChatRoom(false, ticketNo);
				} catch (err) {
					console.log(err.toString());
				}
			}, 700);
		});
		function backToPage(page) {
			router.replace(page);
		}

		const takePhoto = async () => {
			const image = await Camera.getPhoto({
				quality: 60,
				resultType: CameraResultType.Uri,
			});

			catchAsync(async () => {
				store.state.uploadImg.ChatPhotoName = null;
				store.state.uploadImg.ChatPhotoExtension = null;
				store.state.uploadImg.ChatPhoto = null;
				store.state.uploadImg.imgPreview = null;
				const result = await compressImgToBase64(
					image.webPath,
					image.format,
					700,
					700
				);
				if (result) {
					store.state.uploadImg.ChatPhoto = result;
					store.state.uploadImg.isOpenPopup = true;
					store.state.uploadImg.ChatPhotoName = Date.now().toString();
					store.state.uploadImg.imgPreview = image.webPath;
					store.state.uploadImg.ChatPhotoExtension = image.format;
					scroll.value.scrollTo(0, 100000);
				}
			});
		};

		return {
			store,
			ticketID,
			nomorTiket,
			isOpenRef,
			imgPopUp,
			setOpen,
			msg,
			imageOutline,
			sendMessage,
			scroll,
			chevronBackOutline,
			checkmarkCircleOutline,
			sendOutline,
			paperPlaneOutline,
			chatboxOutline,
			userOwn,
			// popUploadOpen,
			goToPageEndChat,
			conversation,
			//ticketInfo,
			onOkButton,
			isChatExp,
			isChatEnd,
			sessionEndDate,
			buttonsAlert,
			chatBg,
			takePhoto,
			viewImage,
			backToPage,
			patientName,
		};
	},
	methods: {},
};
</script>

<style scoped>
/* ion-content{
	--keyboard-offset: 0 !important;
 } */

.sessionEndDate {
	/* color: black; */
	color: #1a2674;
	font-size: 12px;
	width: 100%;
	font-weight: bold;
	cursor: default;
	text-align: center;
	margin: 0 auto;
	padding-top: 8px;
	padding-bottom: 8px;
	position: fixed;
	background-color: rgba(255, 255, 255, 0.651);
	z-index: 100;
}
.boxMessage {
	border-top: 1px solid #f7f7f7;
	bottom: 0;
	background-color: #f5f5f5;
	width: 100%;
	min-height: 70px;
	margin: 0;
	padding: 0;
}
.image-upload > input {
	display: none;
}
.inputMsg {
	padding: 0;
	margin: 0;
	width: 100%;
	height: 45px;
	border: 1px solid #dfdfdf;
	background-color: #fcfcfc;
	border-radius: 5px;
	color: #3b3b3b;
	outline: none;
	padding-left: 4px;
	cursor: text;
}
.imgUpload {
	margin: 0 auto;
	margin-top: 10px;
	position: relative;
	cursor: pointer;
	text-align: center;
}
/* .imgUpload > svg {
	max-width: 50px;
} */
.icon-img {
	color: rgb(173, 173, 173);
	max-width: 50px;
}
.backgroundChat {
	height: 100%;
}
.bgChat {
	height: 100%;
	z-index: 0;
	overflow-y: auto;
	display: flex;
	flex-direction: column-reverse;
	background: center;
	background-size: cover;
	padding-bottom: 135px;
	position: relative;
}
.bubbleWrapper {
	padding: 1px 10px;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	align-self: flex-end;
	color: #fff;
}
.inlineContainer {
	display: inline-flex;
}
.inlineContainer.own {
	flex-direction: row-reverse;
}
.inlineIcon {
	width: 20px;
	object-fit: contain;
}
.ownBubble {
	min-width: 60px;
	max-width: 330px;
	padding: 14px 18px;
	margin: 18px 8px;
	background-color: #d8ffc6;
	border-radius: 10px 10px 0 10px;
	border: 1px solid #d8ffc6;
	color: #000;
	-webkit-box-shadow: 0px 10px 3px -11px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 10px 3px -11px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 10px 3px -11px rgba(0, 0, 0, 0.75);
	font-size: 14px;
	padding-bottom: 24px;
	margin-bottom: 2px;
}
.otherBubble {
	min-width: 60px;
	max-width: 330px;
	padding: 14px 18px;
	margin: 18px 8px;
	background-color: #ffffff;
	border-radius: 10px 10px 10px 0;
	border: 1px solid #ffffff;
	color: #000;
	-webkit-box-shadow: 0px 10px 2px -11px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 10px 2px -11px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 10px 2px -11px rgba(0, 0, 0, 0.75);
	font-size: 14px;
	padding-bottom: 24px;
	margin-bottom: 2px;
}
.own {
	align-self: flex-end;
}
.other {
	align-self: flex-start;
}
span.own,
span.other {
	font-size: 11px;
	color: grey;
	margin-top: -20px;
}
span.own {
	margin-right: 26px !important;
}
span.other {
	margin-left: 26px !important;
}
.image {
	padding-top: 5px;
	margin-bottom: -15px;
	cursor: pointer;
}
.imgPopup {
	margin: 0 auto;
	width: 100%;
	height: auto;
}
/* .time {
	font-size: 12px;
	color: grey;
} */
ion-avatar {
	margin-right: 10px;
}
a {
	outline: none;
}
.chevronBackOutline {
	color: gray;
	font-size: 28px;
	margin-right: 15px;
}
.top-header {
	display: flex;
	flex-direction: column;
}
.newIncomingChat {
	font-size: 30px;
	color: rgb(236, 48, 48);
	margin-right: 15px;
	margin-bottom: 5px;
	cursor: pointer;
}
.totalBadge {
	border-radius: 50%;
	width: 20px;
	height: 20px;
	padding-top: 2px;

	display: block;

	background: rgb(236, 48, 48);
	text-align: center;
	color: white;
	position: relative;
	margin-top: -45px;
	margin-right: -65px;

	font: 14px;
	cursor: pointer;
}
</style>
